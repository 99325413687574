import {
  Navigate,
  Route,
  Routes,
  Outlet,
  useLocation,
  Link,
} from "react-router-dom";
import { OrgRouter } from "./OrgRouter";
// import { RolesRouter } from "./RolesRouter";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useMemo } from "react";
import { Users } from "./Users";
import { useAuthorizer } from "../util/authorizer";
import { NotFound } from "../NotFound";

function a11yProps(index: string) {
  return {
    id: `access-management-tab-${index}`,
    "aria-controls": `access-management-tabpanel-${index}`,
  };
}

function RootLayout() {
  const { pathname } = useLocation();
  const { isAdministrator } = useAuthorizer();
  const allPaths = useMemo(() => {
    const pathsOut = [{
      path: "/access-management/company",
      label: "Companies",
    }];
    if (isAdministrator) {
      pathsOut.push({
        path: "/access-management/end-users",
        label: "End Users",
      });
      pathsOut.push({
        path: "/access-management/thermonova-users",
        label: "Thermonova Users",
      });
    }
    return pathsOut;
  }, [isAdministrator]);
  const currentPath = useMemo(() => {
    return pathname
      .split("/")
      .filter((part, index) => index <= 2)
      .join("/");
  }, [pathname]);
  return (
    <Box>
      <Tabs aria-label="basic tabs example" value={currentPath}>
        {allPaths.map((path) => {
          return (
            <Tab
              key={path.path}
              label={path.label}
              {...a11yProps(path.path)}
              component={Link}
              value={path.path}
              to={path.path}
            />
          );
        })}
      </Tabs>
      <div style={{ paddingTop: 20 }}>
        <Outlet />
      </div>
    </Box>
  );
}

export function AccessManagementRouter() {
  const { isAdministrator, isLoading } = useAuthorizer();

  if (isLoading) {
    return null;
  }

  return (
    <Routes>
      <Route path="access-management/*" element={<RootLayout />}>
        <Route path="company/*" element={<OrgRouter />} />
        {isAdministrator && (
          <>
            <Route path="end-users/*" element={<Users type="end-users" />} />
            <Route
              path="thermonova-users/*"
              element={<Users type="thermonova-users" />}
            />
          </>
        )}
        <Route index element={<Navigate to="company" />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
