import { API } from "aws-amplify";
import { useSelector } from "react-redux";
const patchUserGraphSettings = async (Serialnumber, graphSettings) => {
  const newData = {
    Serialnumber,
    graphSettings,
  };
  const apiName = "ThermonovaAPI";
  const path = "/users/user/graph-settings";
  await API.post(apiName, path, {
    body: newData,
  });
};

const useSaveUserGraphSettingsToLocalStorageAndBackend = () => {
  const { HPData, liveDataset, chartDataset, disabledLineGraphs } = useSelector(
    (state: any) => state.heatpump
  );

  const run = async (override: {
    disabledLineGraphs?: Record<string, string>[];
  } = {}) => {
    localStorage.setItem(
      HPData.Serialnumber,
      JSON.stringify({
        chartDataset: chartDataset,
        liveDataset: liveDataset,
        disabledLineGraphs: override.disabledLineGraphs || disabledLineGraphs,
      })
    );
    patchUserGraphSettings(HPData.Serialnumber, {
      chartDataset: chartDataset,
      liveDataset: liveDataset,
      disabledLineGraphs: override.disabledLineGraphs || disabledLineGraphs,
    });
  };
  return { run };
};

export default patchUserGraphSettings;
export { useSaveUserGraphSettingsToLocalStorageAndBackend };
