import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { EditHPLogic } from "./EditHeatpumpLogic";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";

export default function EditHPView(props: any) {
  const {
    handleSend,
    returnAlert,
    serialnumber,
    name,
    address,
    city,
    zipcode,
    contactPerson,
    contactPersonPhone,
    model,
    handleChange,
    disabledSendButton,
    disabledDeleteButton,
    handleDelete,
    disabledDisconnectButton,
    handleDisconnectGW,
  } = EditHPLogic(props.handleClose, props.updateHPdata, props.HPdata);
  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle>Edit Heatpump</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            disabled
            required
            margin="dense"
            id="serialnumber"
            value={serialnumber}
            label="Serialnumber"
            type="text"
            fullWidth
            onChange={handleChange}
            variant="standard"
          />
          <TextField
            required
            margin="dense"
            id="name"
            value={name}
            label="Name"
            type="text"
            fullWidth
            onChange={handleChange}
            variant="standard"
          />
          <TextField
            required
            margin="dense"
            id="address"
            value={address}
            label="Address"
            type="text"
            fullWidth
            onChange={handleChange}
            variant="standard"
          />
          <TextField
            required
            margin="dense"
            id="city"
            value={city}
            label="City"
            type="text"
            fullWidth
            onChange={handleChange}
            variant="standard"
          />
          <TextField
            required
            margin="dense"
            id="zipcode"
            value={zipcode}
            label="Zipcode"
            type="text"
            fullWidth
            onChange={handleChange}
            variant="standard"
          />
          <TextField
            margin="dense"
            id="contactPerson"
            value={contactPerson}
            label="contact Person"
            type="text"
            fullWidth
            onChange={handleChange}
            variant="standard"
          />
          <TextField
            margin="dense"
            id="contactPersonPhone"
            value={contactPersonPhone}
            label="contact Person Phonenumber"
            type="text"
            fullWidth
            onChange={handleChange}
            variant="standard"
          />
          <FormControl fullWidth variant="standard">
            <InputLabel id="demo-simple-select-label">Model *</InputLabel>
            <Select
              required
              value={model}
              label="Model *"
              onChange={handleChange}
              inputProps={{
                name: "model",
              }}
            >
              <MenuItem value="Nova 110">Nova 110</MenuItem>
              <MenuItem value="Nova 220">Nova 220</MenuItem>
              <MenuItem value="Nova 330">Nova 330</MenuItem>
              <MenuItem value="Nova 440">Nova 440</MenuItem>
              <MenuItem value="GeoNova 26">GeoNova 26</MenuItem>
              <MenuItem value="GeoNova 32">GeoNova 32</MenuItem>
              <MenuItem value="GeoNova 42">GeoNova 42</MenuItem>
              <MenuItem value="GeoNova 52">GeoNova 52</MenuItem>
              <MenuItem value="GeoNova 64">GeoNova 64</MenuItem>
              <MenuItem value="GeoNova 84">GeoNova 84</MenuItem>
              <MenuItem value="Nature Energy">Nature Energy</MenuItem>
              <MenuItem value="Testchamber">Testchamber</MenuItem>
            </Select>
            <FormHelperText>* Required</FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={disabledDisconnectButton}
            onClick={handleDisconnectGW}
          >
            Disconnect Gateway
          </Button>
          <Button disabled={disabledDeleteButton} onClick={handleDelete}>
            Delete
          </Button>
          <Button disabled={disabledSendButton} onClick={handleSend}>
            Save
          </Button>
        </DialogActions>
        {returnAlert()}
      </Dialog>
    </div>
  );
}
