import React from "react";
import { API } from "aws-amplify";
import Alert from "@mui/material/Alert";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { setFilteredHeatpumps, setHeatpumps } from "../../Redux/MainStore";
export const EditHPLogic = (
  handleClose: () => void,
  updateHPList: any,
  data: any
) => {
  const Dispatch = useDispatch();
  const heatpumps = useSelector((state: any) => state.mainData.heatpumps);
  const filteredHeatpumps = useSelector(
    (state: any) => state.mainData.filteredHeatpumps
  );

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [name, setName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [zipcode, setZipcode] = React.useState("");
  const [model, setModel] = React.useState("");
  const [serialnumber, setSerialnumber] = React.useState("");
  const [contactPerson, setContactPerson] = React.useState("");
  const [contactPersonPhone, setContactPersonPhone] = React.useState("");
  const [gwID, setGwID] = React.useState("");
  const [APIalarm, setAPIalarm] = React.useState(0);
  const [disabledSendButton, setDisabledSendButton] = React.useState(true);
  const [disabledDeleteButton, setDisabledDeleteButton] = React.useState(true);
  const [disabledDisconnectButton, setDisabledDisconnectButton] =
    React.useState(true);

  const handleSend = () => {
    postEditHPID()
      .then((response) => {
        handleAPIResponse(response);
      })
      .catch((error) => {
        console.log(error.response);
        handleAPIResponse("error");
      });
  };
  const handleDelete = () => {
    const loadingSnackbarKey = enqueueSnackbar("Deleting heatpump...", {
      variant: "info",
    });
  
    deleteHP()
      .then((response) => {
        Dispatch(setHeatpumps(heatpumps.filter((hp) => hp.id !== serialnumber)));
        Dispatch(setFilteredHeatpumps(filteredHeatpumps.filter((hp) => hp.id !== serialnumber)));
        closeSnackbar(loadingSnackbarKey);
        enqueueSnackbar("Heatpump deleted", {
          variant: "success",
        });
        handleAPIResponse(response);
      })
      .catch((error) => {
        closeSnackbar(loadingSnackbarKey);
        enqueueSnackbar("Error deleting heatpump", {
          variant: "error",
        });
        handleAPIResponse("error");
      });
  };

  const handleDisconnectGW = () => {
    const loadingSnackbarKey = enqueueSnackbar("Disconnecting gateway...", {
      variant: "info",
    });
    disconnectGW()
      .then((response) => {
        closeSnackbar(loadingSnackbarKey);
        enqueueSnackbar("Gateway disconnected", {
          variant: "success",
        });
        handleAPIResponse(response);
      })
      .catch((error) => {
        closeSnackbar(loadingSnackbarKey);
        enqueueSnackbar("Error disconnecting gateway", {
          variant: "error",
        });
        handleAPIResponse("error");
      });
  };

  const handleChange = (e: any) => {
    let switchValue;

    if (e.target.name === "model") {
      switchValue = e.target.name;
    } else {
      switchValue = e.target.id;
    }
    switch (switchValue) {
      case "serialnumber":
        setSerialnumber(e.target.value);
        break;
      case "name":
        setName(e.target.value);
        break;
      case "address":
        setAddress(e.target.value);
        break;
      case "city":
        setCity(e.target.value);
        break;
      case "zipcode":
        setZipcode(e.target.value);
        break;
      case "model":
        setModel(e.target.value);
        break;
      case "contactPerson":
        setContactPerson(e.target.value);
        break;
      case "contactPersonPhone":
        setContactPersonPhone(e.target.value);
        break;

      default:
        break;
    }
  };
  React.useEffect(() => {
    if (
      name !== "" &&
      address !== "" &&
      serialnumber !== "" &&
      city !== "" &&
      zipcode !== "" &&
      model !== ""
    ) {
      setDisabledSendButton(false);
    } else {
      setDisabledSendButton(true);
    }
  }, [name, address, serialnumber, city, zipcode, model]);

  React.useEffect(() => {
    if (Object.keys(data).length !== 0) {
      setName(data.col1);
      setAddress(data.col4);
      setCity(data.col5);
      setZipcode(data.col6);
      setContactPerson(data.col10);
      setContactPersonPhone(data.col11);
      setModel(data.col2);
      setSerialnumber(data.col3);
      setGwID(data.col7);
      setAPIalarm(0);
      if (data.col7 === "" || data.col7 === null || data.col7 === undefined) {
        setDisabledDeleteButton(false);
        setDisabledDisconnectButton(true);
      } else {
        setDisabledDeleteButton(true);
        setDisabledDisconnectButton(false);
      }
    }
  }, [data]);

  React.useEffect(() => {
    if (gwID === "" || gwID === null || gwID === undefined) {
      setDisabledDeleteButton(false);
      setDisabledDisconnectButton(true);
    } else {
      setDisabledDeleteButton(true);
      setDisabledDisconnectButton(false);
    }
  }, [gwID]);

  const handleAPIResponse = (e: string) => {
    //console.log(e)
    if (e === "GW still connected") {
      setAPIalarm(1);
    } else if (e === "HP deleted") {
      setAPIalarm(2);
      //handleClear();
      updateHPList();
      delay(1000).then(() => handleClose());
    } else if (e === "gw datached") {
      updateHPList();
      setGwID("");
    } else if (e === "gw does not exist") {
      updateHPList();
      setGwID("");
    } else if (e === "gw is not atached") {
      updateHPList();
      setGwID("");
    } else if (e === "updated") {
      setAPIalarm(3);
      updateHPList();
      setGwID("");
      delay(1000).then(() => {
        handleClose();
        setAPIalarm(0);
      });
    }

  };

  const handleGWidChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGwID(e.target.value);
  };
  async function deleteHP() {
    const apiName = "ThermonovaAPI";
    const path = "/HP";
    const myInit = {
      // OPTIONAL
      body: {
        serialnumber: serialnumber,
      }, // replace this with attributes you need
    };

    return await API.del(apiName, path, myInit);
  }

  async function postEditHPID() {
    const apiName = "ThermonovaAPI";
    const path = "/HP";
    const myInit = {
      // OPTIONAL
      body: {
        serialnumber: serialnumber,
        name: name,
        address: address,
        city: city,
        zipcode: zipcode,
        contactPerson: contactPerson,
        contactPersonPhone: contactPersonPhone,
        model: model,
        action: "editHP",
      }, // replace this with attributes you need
    };
    return await API.put(apiName, path, myInit);
  }

  async function disconnectGW() {
    const apiName = "ThermonovaAPI";
    const path = "/HP/gatewayid";
    const myInit = {
      // OPTIONAL
      body: {
        gatewayID: gwID,
        serialnumber: serialnumber,
      }, // replace this with attributes you need
    };

    return await API.put(apiName, path, myInit);
  }
  function delay(time: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const returnAlert = () => {
    switch (APIalarm) {
      case 1:
        return <Alert severity="error">Gateway still connected.</Alert>;
      case 2:
        return <Alert severity="success">Heatpump deleted.</Alert>;
      case 3:
        return <Alert severity="success">Heatpump updated.</Alert>;
      default:
        return "";
    }
  };

  return {
    handleSend,
    gwID,
    handleGWidChange,
    returnAlert,
    serialnumber,
    name,
    address,
    city,
    zipcode,
    contactPerson,
    contactPersonPhone,
    model,
    handleChange,
    disabledSendButton,
    disabledDeleteButton,
    handleDelete,
    disabledDisconnectButton,
    handleDisconnectGW,
  };
};
