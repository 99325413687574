import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import RemoveIcon from "@mui/icons-material/Remove";
import Divider from "@mui/material/Divider";

import { useSelector, useDispatch } from "react-redux";
import { setDisabledLineGraphs, setOpenEditDatasetModal } from "../Redux/HP";
import { useSaveUserGraphSettingsToLocalStorageAndBackend } from "./PathUserGraphSettings";

export default function ActualValuesLogic() {
  const { liveDataset, disabledLineGraphs, actualValues } = useSelector(
    (state: any) => state.heatpump
  );
  const { run } = useSaveUserGraphSettingsToLocalStorageAndBackend();
  const dispatch = useDispatch();

  const addToDatesetList = (controller: any, adr: string) => {
    var ind = disabledLineGraphs.findIndex(
      (graphs: any) => graphs.controller === controller && graphs.adr === adr
    );
    var out = disabledLineGraphs.filter(
      (_: any, index: number) => index !== ind
    );
    dispatch(setDisabledLineGraphs(out));
    run({
        disabledLineGraphs: out,
    });
  };
  const removeFromDatesetList = (controller: any, adr: string) => {
    let newdisabledLineGraphs = [];
    newdisabledLineGraphs = [...disabledLineGraphs];
    newdisabledLineGraphs.push({ controller: controller, adr: adr });
    dispatch(setDisabledLineGraphs(newdisabledLineGraphs));
    run({
      disabledLineGraphs: newdisabledLineGraphs,
    });
  };

  const showValues = (
    controller: any,
    name: string,
    value: any,
    color: string,
    adr: string
  ) => {
    var button;
    if (
      disabledLineGraphs.find(
        (graphs: any) => graphs.controller === controller && graphs.adr === adr
      )
    ) {
      button = (
        <IconButton
          onClick={() => {
            addToDatesetList(controller, adr);
          }}
        >
          <RemoveIcon fontSize="medium" style={{ color: "#dddddd" }} />
        </IconButton>
      );
    } else {
      button = (
        <IconButton
          onClick={() => {
            removeFromDatesetList(controller, adr);
          }}
        >
          <RemoveIcon fontSize="medium" style={{ color: color }} />
        </IconButton>
      );
    }
    return (
      <div key={controller + name}>
        <Grid container>
          <Grid
            container
            item
            xs={2}
            md={2}
            lg={1}
            xl={1}
            component={Stack}
            direction="column"
            justifyContent="flex-start"
            style={{ paddingRight: "40px" }}
          >
            {button}
          </Grid>
          <Grid
            container
            item
            xs={6}
            md={6}
            lg={4}
            xl={6}
            component={Stack}
            direction="column"
            justifyContent="center"
            style={{ fontSize: "12px" }}
          >
            {name}
          </Grid>
          <Grid
            container
            item
            xs={2}
            md={2}
            lg={2}
            xl={2}
            component={Stack}
            direction="column"
            justifyContent="center"
            style={{ textAlign: "center" }}
          >
            {value}
          </Grid>
        </Grid>
        <Divider light />
      </div>
    );
  };

  return {
    dispatch,
    liveDataset,
    showValues,
    actualValues,
    setOpenEditDatasetModal,
  };
}
