import { Box, Typography } from "@mui/material";

export function NotFound() {
  return (
    <Box sx={{ textAlign: "center", py: 8 }}>
      <Typography variant="h5" color="text.primary">
        404: Page Not Found
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
        The page you're looking for doesn't exist or you don't have access to it.
      </Typography>
    </Box>
  );
} 