import React from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  setLiveData,
  setXmin,
  setXmax
} from "../Redux/HP";

export default function AlarmLogic(props) {
  const Dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(true);
  const [expanded2, setExpanded2] = React.useState(false);

  const [loading] = React.useState(false);
  const { HPData, xDuration } = useSelector(
    (state: any) => state.heatpump
  );
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

  const handleChange = () => (event, newExpanded) => {
    setExpanded(!expanded);
  };
  const openGraph = (date: string) => {
    const timestamp = new Date(date).getTime();

    props.setSelectedMenu("Graph");
    props.setMainView("Graph");
    props.setTitle(HPData?.Name + "- Graph");

    // timetravel to timestamp
    Dispatch(setLiveData(false));
    Dispatch(setXmin(timestamp - xDuration / 2));
    Dispatch(setXmax(timestamp + xDuration / 2));
  };

  const getMasterSlaveView = (_NodeID) => {
    const NodeID = typeof _NodeID === "number" ? _NodeID.toString() : _NodeID;
    if (HPData.Model === "Nature Energy") {
      return (
        <span style={{ marginRight: 10 }}>
          {NodeID === "1" ? "Master" : "Slave " + (NodeID - 1)}
        </span>
      );
    } else {
      return (
        <span style={{ marginRight: 10 }}>
          {NodeID === "100" ? "Master" : "Slave " + NodeID}
        </span>
      );
    }
  };

  return {
    Item,
    expanded,
    expanded2,
    handleChange,
    loading,
    openGraph,
    setExpanded2,
    getMasterSlaveView,
  };
}
