import React from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import ButtonGroup from "@mui/material/ButtonGroup";

import YAxisLogic from "./YAxisLogic";

export default function YAxisView() {
  const { dispatch, setYmax, setYmin, ymax, ymin, ysteps } = YAxisLogic();

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      sx={{ height: "100%", padding: "10px 0px 65px 10px" }}
    >
      <ButtonGroup
        orientation="vertical"
        aria-label="vertical outlined button group"
      >
        <IconButton
          color="primary"
          aria-label="add"
          component="span"
          onClick={() => dispatch(setYmax(ymax + ysteps))}
        >
          <AddIcon />
        </IconButton>
        <IconButton
          color="primary"
          aria-label="add"
          component="span"
          onClick={() => dispatch(setYmax(ymax - ysteps))}
        >
          <RemoveIcon />
        </IconButton>
      </ButtonGroup>

      <ButtonGroup
        orientation="vertical"
        aria-label="vertical outlined button group"
      >
        <IconButton
          color="primary"
          aria-label="remove"
          component="span"
          onClick={() => dispatch(setYmin(ymin + ysteps))}
        >
          <AddIcon />
        </IconButton>
        <IconButton
          color="primary"
          aria-label="remove"
          component="span"
          onClick={() => dispatch(setYmin(ymin - ysteps))}
        >
          <RemoveIcon />
        </IconButton>
      </ButtonGroup>
    </Grid>
  );
}
